import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/Header/Header"
import Overlay from "../components/Overlay/Overlay"
import Feature from "../components/Feature/Feature"
import CallToAction from "../components/CallToAction/CallToAction"
import Portfolio from "../components/Portfolio/Portfolio"
import Footer from "../components/Footer/Footer"
import "../assets/scss/main.scss"

export default (props) => (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.data.site.siteMetadata.title}</title>
        <link rel="canonical" href={props.data.site.siteMetadata.siteUrl} />
      </Helmet>
      <Header logo={props.data.logo.childImageSharp.fluid} />
      <Overlay
        text={props.data.homePage.overlay.text}
        image={props.data.homePage.overlay.image.childImageSharp.fluid}
        />
      <CallToAction title={props.data.homePage.about.title} text={props.data.homePage.about.text} />
      <section className="py-12 section-image">
        <Img fluid={props.data.plasticImg.childImageSharp.fluid} />
      </section>
      <div>
        <div className="colorBlock"></div>
      </div>
      <section>
        <Feature text={props.data.homePage.feature.text} image={props.data.homePage.feature.image.childImageSharp.fluid} />
      </section>
      <section className="py-8">
        <Portfolio title="Playground" projects={props.data.allProjectsJson.edges} />
      </section>
      <section className="section-image--long">
        <Img fluid={props.data.bottleImg.childImageSharp.fluid} style={{maxWidth: "400px"}} />
      </section>
      <section className="py-8">
        <Footer social={props.data.socialJson} copyright={props.data.site.siteMetadata.copyright} logo={props.data.logoFooter.childImageSharp.fluid} />
      </section>
    </div>
)


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        copyright
      }
    }
    logo: file(relativePath: { eq: "esential-noua-logo-text.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoFooter: file(relativePath: { eq: "esential-noua-logo-footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homePage: homeJson {
      title
      overlay {
        text
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      about {
        title
        text
      }
      feature {
        text
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    plasticImg: file(relativePath: { eq: "plastic-hand.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottleImg: file(relativePath: { eq: "bottle-beach.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allProjectsJson {
      edges {
        node {
            id
            title
            url
            image {
              childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
              }
            }
        }
      }
    }
    socialJson {
      instagram
      facebook
      twitter
      linkedin
      email
    }
  }
`